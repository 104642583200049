<template>
  <div class="white">
    <move-email
      v-model="showMoveModal"
      :email="emailContent"
      @close="close"
    />
    <v-row class="rows email-data px-6" :data-email-id="emailContent.id" no-gutters>
      <v-col cols="6">
        <div :class="{flexible: !showMoreActive}">
          <v-label>
            From:
          </v-label>
          <span :class="{truncated: !showMoreActive}">{{ formatEmailList(emailContent.from_address) }}</span>
        </div>
        <div :class="{flexible: !showMoreActive}">
          <v-label>
            To:
          </v-label>
          <span :class="{truncated: !showMoreActive}">{{ formatEmailList(emailContent.to_addresses) }}</span>
        </div>
        <div :class="{flexible: !showMoreActive}">
          <v-label v-if="emailContent.cc_addresses">
            CC:
          </v-label>
          <span :class="{truncated: !showMoreActive}">{{ formatEmailList(emailContent.cc_addresses) }}</span>
        </div>
        <div :class="{flexible: !showMoreActive}">
          <v-label v-if="emailContent.bcc_addresses">
            BCC:
          </v-label>
          <span :class="{truncated: !showMoreActive}">{{ formatEmailList(emailContent.bcc_addresses) }}</span>
        </div>
        <div class="show-more" @click="toggleShowMore">
          {{ showMoreText }}
        </div>
      </v-col>
      <v-col cols="4">
        <v-label v-if="hasAttachments || emailContent.has_pending_attachments">
          Attachments:
        </v-label>
        <attachment-links-list v-if="hasAttachments" :attachments="emailContent.attachments" />

        <v-tooltip v-if="emailContent.has_pending_attachments" location="bottom" z-index="9999">
          <template v-slot:activator="{ props }">
            <v-icon v-bind="props" color="red">
              mdi-alert
            </v-icon>
            <span>Pending</span>
          </template>
          <span>This email has pending attachments.</span>
        </v-tooltip>
      </v-col>
      <v-col cols="2">
        <div class="float-right">
          <v-tooltip location="bottom">
            <template v-slot:activator="{ props }">
              <qtm-btn
                v-bind="props"
                icon
                tertiary
                @click="$emit('reply', emailContent, 'single')"
              >
                <v-icon>mdi-reply</v-icon>
              </qtm-btn>
            </template>
            <span>Reply email</span>
          </v-tooltip>

          <v-menu content-class="qtm-border" offset-y>
            <template v-slot:activator="{ props }">
              <qtm-icon-btn v-bind="props" icon="mdi-dots-horizontal" />
            </template>
            <v-list class="text-mid-grey">
              <v-list-item v-for="item in emailMenuItems" :key="item.title" @click="item.onClick">
                <template v-slot:prepend>
                  <v-icon>{{ item.icon }}</v-icon>
                </template>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-col>
    </v-row>
    <v-row class="rows email-content">
      <v-col class="inner-email-content text-black" cols="10" offset="1">
        <safe-html-renderer :html="emailContent.body" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import AttachmentLinksList from '@/components/attachment-links-list'
import MoveEmail from '@/components/admin/emails/move-email'
import SafeHtmlRenderer from '@/components/admin/emails/safe-html-renderer'

export default {
  name: 'view-email-detail',
  components: { AttachmentLinksList, MoveEmail, SafeHtmlRenderer },
  props: {
    emailContent: {
      type: Object,
      required: true
    },
    canCreateInvoice: {
      type: Boolean,
      default: false
    },
  },
  emits: ['create-invoice', 'refresh', 'reply'],
  data() {
    return {
      showMoveModal: false,
      showMoreActive: false,
    }
  },
  computed: {
    emailMenuItems() {
      const items = [
        {
          title: 'Reply all',
          icon: 'mdi-reply-all',
          onClick: () => this.$emit('reply', this.emailContent, 'all'),
        },
        {
          title: 'Forward',
          icon: 'mdi-forward',
          onClick: () => this.$emit('reply', this.emailContent, 'forward'),
        },
        {
          title: 'Remove BOUNCED notification',
          icon: 'mdi-alert',
          onClick: this.markAsUnbounced,
          exclude: !this.isBounced,
        },
        {
          title: 'Move to another order',
          icon: 'mdi-folder-move',
          onClick: this.moveEmail,
        },
        {
          title: 'Mark as unread',
          icon: 'mdi-email',
          onClick: this.markAsUnread,
          exclude: !(this.isInbound && this.isRead),
        },
        {
          title: 'Mark as read',
          icon: 'mdi-email-open',
          onClick: this.markAsRead,
          exclude: !(this.isInbound && !this.isRead),
        },
        {
          title: 'Pin email to screen',
          icon: 'mdi-pin',
          onClick: () => this.showGlobalEmailViewer(this.emailContent),
        },
        {
          title: 'Create invoice',
          icon: 'mdi-receipt-text-plus',
          onClick: () => this.$emit('create-invoice', this.emailContent),
          exclude: !this.canCreateInvoice || !this.isInbound,
        },
      ]

      return items.filter(item => !item.exclude)
    },
    isInbound() {
      return this.emailContent.email_type === 'inbound'
    },
    isRead() {
      return this.emailContent.tracking_status === 'opened'
    },
    isBounced() {
      return ['bounced', 'rejected'].includes(this.emailContent.tracking_status)
    },
    hasAttachments() {
      return this.emailContent && this.emailContent.attachments && this.emailContent.attachments.length
    },
    showMoreText() {
      return this.showMoreActive ? 'show less' : 'show more'
    },
  },
  methods: {
    ...mapMutations({
      removeBouncedEmail: 'admin/removeBouncedEmail',
      removeUnreadEmail: 'admin/removeUnreadEmail',
      showGlobalEmailViewer: 'admin/showGlobalEmailViewer',
    }),
    async markAsRead() {
      try {
        await this.$api.v1.notifications.email.patch(this.emailContent.id, { tracking_status: 'opened' })
        await this.$api.v1.notifications.email.markAsRead(this.emailContent.id)
        this.emailContent.tracking_status = 'opened'
        this.removeUnreadEmail(this.emailContent)
        this.$emit('refresh')
      }
      catch (error) {
        this.$error.report(error)
      }
    },
    markAsUnbounced() {
      this.markAsRead(this.emailContent)
      this.removeBouncedEmail(this.emailContent)
    },
    close() {
      this.showMoveModal = false
      this.$emit('refresh')
    },
    moveEmail() {
      this.showMoveModal = true
    },
    async markAsUnread() {
      try {
        await this.$api.v1.notifications.email.patch(this.emailContent.id, { tracking_status: 'delivered' })
        this.emailContent.tracking_status = 'delivered'
        this.$emit('refresh')
      }
      catch (error) {
        this.$error.report(error)
      }
    },
    toggleShowMore() {
      this.showMoreActive = !this.showMoreActive
    },
    formatEmailList(emailList) {
      if (emailList) {
        if (emailList.includes(',')) {
          return emailList.replace(/,/g, ';\n')
        }
      }
      return emailList
    },
    openUrlsInNewTab(event) {
      const externalUrl = event.target.getAttribute('href')
      if (event.target.tagName === 'A' && externalUrl) {
        window.open(externalUrl)
      }
    }
  }
}
</script>
<style scoped>
  .email-data {
    color: rgb(var(--v-theme-mid-light-grey)) !important;
    padding: 0px 10px 10px 10px;
    font-size: 1rem;
  }
  .email-data label {
    color: rgb(var(--v-theme-mid-dark-grey)) !important;
    font-size: 1rem;
    padding-right: 0.4rem;
  }
  .email-content {
    padding: 10px 0;
    min-height: 300px;
  }
  .theme--dark .inner-email-content {
    background-color: white;
    border-bottom: 3px solid black;
    border-right: 3px solid black;
    color: #333;
    padding: 20px;
  }
  .flexible {
    display: flex;
  }
  .truncated {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 4px;
  }
  .show-more {
    font-size: 1rem;
    color: rgb(var(--v-theme-interactive));
  }
  .show-more:hover {
    cursor: pointer;
  }
</style>
