<template>
  <qtm-dialog-card
    v-if="emailContent"
    v-model="show"
    scrollable
    width="1200"
  >
    <template v-slot:title>
      {{ emailContent.subject || 'No subject' }}
      <div class="dialog-buttons">
        <qtm-btn
          v-if="emailContent.rfq"
          @click="showOrder(emailContent)"
        >
          <v-icon>
            mdi-open-in-new
          </v-icon>
          View Order
        </qtm-btn>

        <v-chip
          v-if="emailContent.is_general_inbox"
          color="cyan"
          label
          text-color="white"
          variant="flat"
        >
          General inbox
        </v-chip>

        <qtm-icon-btn icon="mdi-close" color="interactive" title="Close" @click="closeEmail()" />
      </div>
    </template>
    <v-divider />
    <view-email-detail
      class="email-dialog"
      :email-content="emailContent"
      :order="{}"
      @reply="showReplyDialog"
    />

    <send-email
      v-if="showSendEmailDialog"
      :company-id="inReplyTo?inReplyTo.company:null"
      :replied-email="inReplyTo"
      :reply-type="replyType"
      :order="(inReplyTo && inReplyTo.rfq)?{ id:inReplyTo.rfq }:null"
      :show="showSendEmailDialog"
      @close="hideSendingDialog"
    />
  </qtm-dialog-card>
</template>

<script>
import { mapMutations } from 'vuex'
import ViewEmailDetail from '@/components/admin/emails/view-email-detail'
import SendEmail from '@/components/admin/emails/send-email'

export default {
  name: 'dialog-view-email-detail',
  components: { ViewEmailDetail, SendEmail },
  props: {
    modelValue: {
      type: Object,
      default: null
    },
  },
  emits: ['update:model-value'],
  data() {
    return {
      showSendEmailDialog: false,
      inReplyTo: null,
      replyType: 'single',
    }
  },
  computed: {
    emailContent: {
      get() { return this.modelValue },
      set() { this.$emit('update:model-value', null) }
    },
    show: {
      get() { return !!this.emailContent },
      set() { this.$emit('update:model-value', null) }
    },
  },
  methods: {
    ...mapMutations({
      activateOrder: 'admin/activateOrder'
    }),
    showOrder(email) {
      this.activateOrder({ id: email.rfq })
    },
    closeEmail() {
      this.emailContent = null
    },
    showReplyDialog(email, type) {
      this.inReplyTo = email
      this.replyType = type
      this.showSendEmailDialog = true
    },
    hideSendingDialog() {
      this.inReplyTo = null
      this.showSendEmailDialog = false
    },
  }
}
</script>

<style lang="scss" scoped>
  .dialog-buttons {
    position: absolute;
    top: 0.7rem;
    right: 0.5rem;
  }
  .email-dialog {
    margin: 0 -24px -20px -24px;
    padding: 12px;
  }
</style>
<style lang="scss">
  .email-dialog .email-data {
    background-color: white;
    color: black;
    padding-top: 10px;
  }
</style>
