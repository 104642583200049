<template>
  <qtm-btn :loading="loading" secondary @click="confirm = true">
    Create Order
    <qtm-dialog-card v-model="confirm" title="Create order from this email?">
      <qtm-radio-group v-model="orderType" class="ml-3">
        <qtm-radio value="CSD">
          <template v-slot:label>
            <div class="text-secondary">
              Create <b>CSD</b> order
            </div>
          </template>
        </qtm-radio>
        <qtm-radio value="RFQ">
          <template v-slot:label>
            <div class="text-secondary">
              Create <b>RFQ</b> order
            </div>
          </template>
        </qtm-radio>
        <qtm-radio value="INVOICE">
          <template v-slot:label>
            <div class="text-secondary">
              Create <b>CSD INVOICE</b> order <span class="text-caption text-grey">(Using CSD flow)</span>
            </div>
          </template>
        </qtm-radio>
        <qtm-radio value="INVOICE_APPROVAL">
          <template v-slot:label>
            <div class="text-secondary">
              Create <b>INVOICE</b> order
            </div>
          </template>
        </qtm-radio>
      </qtm-radio-group>
      <template v-slot:actions>
        <v-spacer />
        <qtm-btn :loading="creatingOrder" tertiary @click="confirm = false">
          Cancel
        </qtm-btn>
        <qtm-btn :loading="creatingOrder" @click="createOrder">
          Confirm
        </qtm-btn>
      </template>
    </qtm-dialog-card>
  </qtm-btn>
</template>

<script>
export default {
  name: 'order-from-email-btn',
  props: {
    email: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['order-created'],
  data() {
    return {
      confirm: false,
      creatingOrder: false,
      orderType: 'CSD',
    }
  },
  methods: {
    async createOrder() {
      const data = {
        email: this.email.id,
        from_call_to_vendor: this.orderType === 'CSD' || this.orderType === 'INVOICE',
        is_invoice: this.orderType === 'INVOICE',
        is_invoice_approval: this.orderType === 'INVOICE_APPROVAL'
      }

      this.creatingOrder = true
      try {
        const order = await this.$api.v1.rfqs.fromEmail(data)

        this.$emit('order-created', order)
      }
      catch (error) {
        this.$error.report(error)
      }
      this.confirm = false
      this.creatingOrder = false
    }
  }
}
</script>
