<template>
  <iframe
    ref="htmlContainer"
    class="html-container"
    sandbox="allow-same-origin allow-popups allow-popups-to-escape-sandbox"
  />
</template>

<script>

export default {
  name: 'safe-html-renderer',
  components: { },
  props: {
    html: {
      type: String,
      default: ''
    },
  },
  watch: {
    html() {
      this.loadHTML(this.html)
    }
  },
  async mounted() {
    await this.$nextTick()
    this.loadHTML(this.html)
  },
  methods: {
    loadHTML(html) {
      const iframe = this.$refs.htmlContainer
      const iframedoc = iframe.contentDocument || iframe.contentWindow.document
      // Fix for Firefox https://bugzilla.mozilla.org/show_bug.cgi?id=695868
      iframedoc.open()
      iframedoc.close()
      iframedoc.body.innerHTML = `${html} <base target="_blank" />`
      this.refreshScrollHeight()
      setTimeout(this.refreshScrollHeight, 1000)
    },
    refreshScrollHeight() {
      const iframe = this.$refs.htmlContainer
      if (iframe) {
        iframe.style.height = `${10 + (iframe.contentWindow.document.body.scrollHeight)}px`
      }
    }
  }
}
</script>
<style scoped>
  iframe.html-container {
    border: 0;
    height: 100%;
    min-height: 380px;
    overflow-y: hidden;
    width: 100%;
  }
</style>
