<template>
  <qtm-dialog-card v-bind="$attrs" persistent title="Move email to another order" width="700">
    <qtm-icon-btn class="close-button" icon="mdi-close" @click="close" />
    <div>
      <b class="text--primary">From:</b>
      {{ email.from_address }}
    </div>
    <div>
      <b class="text--primary">To:</b>
      {{ email.to_addresses }}
    </div>
    <div v-if="email.cc_addresses">
      <b class="text--primary">CC:</b>
      {{ email.cc_addresses }}
    </div>
    <div>
      <b class="text--primary">Subject:</b>
      {{ email.subject }}
    </div>
    <div v-if="email.attachments && email.attachments.length">
      <b class="text--primary">Attachments:</b>
      <attachment-links-list :attachments="email.attachments" />
    </div>
    <div>
      <b class="text--primary">Body:</b>
      <div class="email-body-continer">
        <safe-html-renderer :html="email.body" />
      </div>
    </div>
    <br>
    <b class="text--primary">Move email to:</b>
    <v-row>
      <v-col>
        <a @click.stop="changeSearchType">
          {{ useBackendSearch ? "Use Related Order Search" : "Use All Order Search" }}
        </a>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="9">
        <order-search
          v-if="useBackendSearch"
          density="compact"
          max-width="485"
          @update:model-value="backendOrderSelected"
        />
        <qtm-autocomplete
          v-else
          v-model="order"
          dense
          hide-details
          :item-title="orderName"
          :items="orders"
          placeholder="Search for order..."
        />
      </v-col>
      <v-col cols="3">
        <qtm-btn :loading="loading" @click="moveEmail">
          Move
          <v-icon location="right">
            mdi-send
          </v-icon>
        </qtm-btn>
      </v-col>
    </v-row>

    <template v-slot:actions>
      <v-spacer />
      <qtm-btn tertiary @click="sendToSupport">
        <v-icon>mdi-face-agent</v-icon>
        Send to support
      </qtm-btn>
      <order-from-email-btn v-if="!email.rfq" :email="email" :loading="loading" @order-created="orderCreated" />
      <qtm-btn v-if="!email.is_general_inbox" :loading="loading" secondary @click="moveToGeneralInbox">
        General Inbox
      </qtm-btn>
    </template>
  </qtm-dialog-card>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import AttachmentLinksList from '@/components/attachment-links-list'
import OrderSearch from '@/components/search/order-search'
import OrderFromEmailBtn from '@/components/admin/emails/order-from-email-btn'
import SafeHtmlRenderer from '@/components/admin/emails/safe-html-renderer'

export default {
  name: 'move-email',
  components: {
    AttachmentLinksList, OrderSearch, OrderFromEmailBtn, SafeHtmlRenderer
  },
  props: {
    email: {
      type: Object,
      required: false,
      default: null
    },
    openOrderAfterMoving: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close'],
  data() {
    return {
      loading: false,
      order: null,
      orders: [],
      useBackendSearch: false
    }
  },
  computed: {
    ...mapState('admin', { allOrders: 'orders' }),
  },
  watch: {
    email() {
      this.order = null
      this.fetchRelatedOrders()
    }
  },
  mounted() {
    this.fetchRelatedOrders()
  },
  methods: {
    ...mapMutations({
      activateOrder: 'admin/activateOrder',
      removeUnassociatedEmail: 'admin/removeUnassociatedEmail'
    }),
    async fetchRelatedOrders() {
      if (!this.email || !this.email.id) {
        return
      }

      this.loading = true
      try {
        const resp = await this.$api.v1.notifications.email.getRelatedRfqs(this.email.id)
        const filteredList = resp.data.filter(element => element.id !== this.email.rfq)
        this.orders = filteredList.sort((a, b) => a.qtm_reference_number.localeCompare(b.qtm_reference_number))
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
    orderName(order) {
      const requesterName = order.requester_last_name
        ? ` (${order.requester_first_name} ${order.requester_last_name})`
        : ''
      return `${order.qtm_reference_number} - ${order.reference_name}${requesterName}`
    },
    async close() {
      this.$emit('close')
    },
    async moveToGeneralInbox() {
      this.loading = true
      try {
        await this.$api.v1.notifications.email.patch(this.email.id, { is_general_inbox: true, rfq: null })
        this.close()
        if (this.openOrderAfterMoving) {
          this.removeUnassociatedEmail(this.email)
        }
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
    async sendToSupport() {
      this.loading = true
      try {
        await this.$api.v1.notifications.email.moveToSupport(this.email.id, {})
        this.close()
        if (this.openOrderAfterMoving) {
          this.removeUnassociatedEmail(this.email)
        }
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
    async moveEmail() {
      if (!this.order) {
        this.$toast.warning('Please choose an order to move the email to.')
        return
      }
      if (this.email.rfq === this.order) {
        this.$toast.warning('This email is currently associated to that order, please choose another one.')
        return
      }

      this.loading = true
      try {
        await this.$api.v1.notifications.email.patch(this.email.id, { rfq: this.order, is_general_inbox: false })
        this.close()
        if (this.openOrderAfterMoving) {
          this.removeUnassociatedEmail(this.email)
          const order = this.allOrders.find(checkedOrder => checkedOrder.id === this.order)
          this.activateOrder(order)
        }
      }
      catch (error) {
        this.$toast.error(error)
        this.$error.report(error)
      }
      this.loading = false
    },
    orderCreated(order) {
      this.removeUnassociatedEmail(this.email)
      this.$store.commit('admin/updateOrder', order)
      this.activateOrder(order)
      this.close()
    },
    backendOrderSelected({ id } = {}) {
      this.order = id
    },
    changeSearchType() {
      this.useBackendSearch = !this.useBackendSearch
    }
  },
}
</script>
<style scoped>
  .move-email-title {
    margin-bottom: 10px;
    margin-top: 15px;
  }
  .close-button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
  .email-body-continer {
    max-height: 300px;
    overflow-y: auto;
  }
</style>
